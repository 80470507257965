.darkMode {
  --background-color: #06080c;
  --text-color: #fff9;
  --text-variant-color: #fff6;
  --h1-color: #fff;
  --h2-color: #f2f2f2;
  --h3-color: #fff;
  --caption-color: #fff6;
  --p-color: #fff9;
  --primary-button-color: #81ced8;
  --menu-bar-color: #06080cc4;
  --item-background: #101112;
  --menu-item-selected-bg: #ffffff14;
  --menu-item-selected-color: #fff;
  --menu-bar-border-color: #ffffff24;
  --grey1: #fff9;
  --grey2: #ffffff80;
  --primary: #fffc;
}

.darkMode .card_customizer {
  background-image: url("cardcustomizer_dark.6685d6b0.webp") !important;
}

.darkMode #projects div.tile .flip-card-front, .darkMode #projects div.tile .flip-card-back {
  background-color: #181819;
}

.darkMode #hero #hero_bg {
  background-image: url("herobackground_dark.a962e7a9.webp");
}

.darkMode #back_to_top img, .darkMode #back_to_home img {
  filter: none !important;
}

.darkMode #clients .logo {
  filter: invert(0);
}

@media (width <= 640px) {
  .darkMode #mobile_mockup {
    background-image: url("mobile_mockup_dark.57f75f69.webp");
  }

  .darkMode #hero #hero_bg {
    background-image: url("herobackgroundmobile_dark.97bec87f.webp");
  }

  .darkMode #mobile_mockup_overlay {
    background: linear-gradient(#06080c00 0%, #06080c 100%);
  }
}

.darkMode #projects #haumea {
  color: red;
  background-image: url("haumea_dark.3c4fc7b7.webp");
}

.darkMode #projects #bloxley {
  background-image: url("bloxley_dark.ce194410.webp");
}

.darkMode .icon span {
  filter: invert(.5);
}

.darkMode .icon #flutter {
  background-image: url("flutter_dark.bb99371a.svg");
}

.darkMode .icon #rive {
  background-image: url("rive_dark.9cf31f99.svg");
}

.darkMode .icon #angular {
  background-image: url("angular_dark.5b8fe8cd.svg");
}

.darkMode .icon #elasticsearch {
  background-image: url("elasticsearch_dark.0f2f1a17.svg");
}

.darkMode .icon #firebase {
  background-image: url("firebase_dark.486f34ec.svg");
}

.darkMode .icon #pocketbase {
  background-image: url("pocketbase_dark.2c7f46ac.svg");
}

/*# sourceMappingURL=index.6dcffccd.css.map */
