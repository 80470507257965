// Dark mode text styles
.darkMode {
  --background-color: #06080C;
  --text-color: rgba(255, 255, 255, 0.60);
  --text-variant-color: rgba(255, 255, 255, 0.40);
  --h1-color: #FFFFFF;
  --h2-color: #F2F2F2;
  --h3-color: #FFF;
  --caption-color: rgba(255, 255, 255, 0.40);
  --p-color: rgba(255, 255, 255, 0.60);
  --primary-button-color: #81CED8;

  --menu-bar-color: rgba(6, 8, 12, 0.77);
  --item-background: #101112;
  --menu-item-selected-bg: rgba(255, 255, 255, 0.08);
  --menu-item-selected-color: #FFFFFF;
  --menu-bar-border-color: rgba(255, 255, 255, 0.14);

  --grey1: rgba(255, 255, 255, 0.60);
  --grey2: rgba(255, 255, 255, 0.50);

  --primary: rgba(255, 255, 255, 0.80);

  .card_customizer {
    background-image: url(../assets/images/cardcustomizer_dark.webp) !important;
  }

  #projects {
    div.tile {

      .flip-card-front {
        background-color: #181819;
      }

      .flip-card-back {
        background-color: #181819;
      }
    }
  }

  #hero {
    #hero_bg {
      background-image: url(../assets/images/herobackground_dark.webp);
    }
  }

  #back_to_top {
    img {
      filter: none !important;
    }
  }

  #back_to_home {
    img {
      filter: none !important;
    }
  }

  #clients {
    .logo {
      filter: invert(0);
    }
  }

  @media (max-width: 640px) {
    #mobile_mockup {
      background-image: url(../assets/images/mobile_mockup_dark.webp);
    }

    #hero {
      #hero_bg {
        background-image: url(../assets/images/herobackgroundmobile_dark.webp);
      }
    }

    #mobile_mockup_overlay {
      background: linear-gradient(180deg, rgba(6, 8, 12, 0.00) 0%, #06080C 100%);
    }

  }

  #projects {
    #haumea {
      background-image: url(../assets/images/haumea_dark.webp);
      color: red;
    }


    #bloxley {
      background-image: url(../assets/images/bloxley_dark.webp);
    }
  }

  .icon {
    span {
      filter: invert(0.5);
    }

    #flutter {
      background-image: url(../assets/icons/tech/flutter_dark.svg);
    }

    #rive {
      background-image: url(../assets/icons/tech/rive_dark.svg);
    }

    #angular {
      background-image: url(../assets/icons/tech/angular_dark.svg);
    }

    #elasticsearch {
      background-image: url(../assets/icons/tech/elasticsearch_dark.svg);
    }

    #firebase {
      background-image: url(../assets/icons/tech/firebase_dark.svg);
    }

    #pocketbase {
      background-image: url(../assets/icons/tech/pocketbase_dark.svg);
    }
  }


}
